<template>
  <collective-detail breadcrumb-route="subcommunities" item-type="subcommunities" item-type-single="subcommunity" />
</template>

<script>
import CollectiveDetail from '@/views/apps/collectives/components/CollectiveDetail.vue';

export default {
  name: 'Subcommunity',
  components: {
    CollectiveDetail,
  },
};
</script>
